import Layout from "../../../components/layout";
import { Link } from "gatsby";
import MainContent from "../../../components/maincontent";
import React from "react";
import Schedule from "../../../components/schedule/2022/schedule";
import Seo from "../../../components/seo";

const IndexPage = () => (
  <Layout>
    <Seo
      title="Camp 2022 - Schedule"
      description="Schedule for Charity Grace Bible Camp 2021"
      lang="en"
      meta={[
        {
          content: "God, Jesus, Gospel, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans, Charity Grace Bible Church, Illinois",
          name: "keywords"
        }
      ]}
    />

    <MainContent
      hasArticle={false}
      hasBanner={false}
    >
      <div className="grid--4 grid-padding align--center">
        <Link className="button button--grey" to="/camp/">
          <i className="fa fa-caret-left" aria-hidden="true" /> Back
        </Link>
      </div>

      <div className="grid--4 grid-padding align--center">
        <h2>Camp Schedule 2022</h2>
        <h3>Pauline Doctrine</h3>
        <span className="caption">October 6th - October 9th</span>
      </div>

      <div className="grid--4 grid-padding align--center">
        <a
          href="/assets/pdf/CGBC_2022_Schedule.pdf"
          className="button button--green"
          download=""
          target="_blank"
          rel="noopener"
        >
          <i className="fa fa-file-pdf-o" aria-hidden="true" /> Download PDF
        </a>
      </div>
    </MainContent>
    <Schedule />
  </Layout>
);

export default IndexPage;
