import PropTypes from "prop-types";
import React from "react";

export default class TableRow extends React.Component {

  render() {
    const { color, day, event, notes, time, where } = this.props;
    return (
      <tr style={{ height: "3rem" }} className={`table-row table-color--${color}`}>
        <td className="table-column table-column--2b">
          <h2 className="row-label">{day}</h2>
        </td>
        <td className="table-column table-column--2b">
          <p className="column-label--time">{time}</p>
        </td>
        <td className="table-column table-column--2b">
          <p className="column-label--event">{event}</p>
        </td>
        <td className="table-column table-column--2b">
          <p className="column-label--notes">{notes}</p>
        </td>
        <td className="table-column table-column--2b">
          <p className="column-label--where">{where}</p>
        </td>
      </tr>
    );
  }

}

TableRow.propTypes = {
  color: PropTypes.string,
  day: PropTypes.string,
  event: PropTypes.string,
  notes: PropTypes.string,
  time: PropTypes.string,
  where: PropTypes.string
};

TableRow.defaultProps = {
  color: "",
  day: "",
  event: "-",
  notes: "-",
  time: "-",
  where: "-"
};
